module.exports = [{
      plugin: require('/opt/render/project/src/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/render/project/src/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#8cc63f"},
    },{
      plugin: require('/opt/render/project/src/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"omitGoogleFont":true,"pathToConfigModule":"src/utils/typography.js"},
    },{
      plugin: require('/opt/render/project/src/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-137904569-1","head":false,"exclude":["/preview/**","/do-not-track/me/too/"]},
    },{
      plugin: require('/opt/render/project/src/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
